import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "reportDetails"
};
const _hoisted_2 = {
  style: {
    "max-width": "840px",
    "min-width": "840px",
    "margin": "0 auto"
  }
};
const _hoisted_3 = ["innerHTML"];
import { h, ref, computed } from "vue";
import { useStore } from "./store";
import { useRoute } from "vue-router";
export default {
  __name: 'index',

  setup(__props) {
    let store = useStore();
    let route = useRoute();
    store.getReportDetails(route.query.id);
    const contentRef = ref();
    const contentDetails = computed(() => {
      return html => {
        return `<h1 class="title">${store.details.contentTitle}</h1>${html}`;
      };
    });
    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_withDirectives(_createElementVNode("div", {
        ref_key: "contentRef",
        ref: contentRef,
        style: {
          "min-height": "500px"
        },
        innerHTML: _unref(contentDetails)(_unref(store).details.contentDetails)
      }, null, 8, _hoisted_3), [[_directive_loading, _unref(store).$loading.getReportDetails]])])]);
    };
  }

};