import { defineStore } from "pinia";
import { reportDetails } from "@/apis/home";

export const useStore = defineStore("reportDetails", {
  state: () => {
    return {
      details: {},
    };
  },
  actions: {
    async getReportDetails(...args) {
      var res = await reportDetails(...args);
      this.details = res;
    },
  },
});
